.more_btn{
    font-size: 16px;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid $c_text;
    transition: .3s;
    text-align: center;
    color: $c_text;
    @if $type == sp{
        padding: 16px 40px 16px;
        width: 90%;
    }
    @if $type == pc{
        padding: 16px 40px 13px;
    }
}
section{
    @if $type == sp{
        padding: 60px 0;
    }
    @if $type == pc{
        padding: 100px 0;
    }
    &.bg_gray{
        background-color: #f1f1f1;
    }
}
h2{
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 0.05em;
    @if $type == sp{
        font-size: 28px;
    }
    @if $type == pc{
        font-size: 38px;
    }
}
.sub_h2{
    font-weight: 600;
    text-align: center;
    @if $type == sp{
        font-size: 28px;
        font-size: 11px;
    }
    @if $type == pc{
        font-size: 13px;
        margin-top: 5px;
    }
}
#mv{
    @if $type == sp{
        width: 100%;
        height: 100vh;
        background: url(../images/home/mv_sp.jpg) no-repeat center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @if $type == pc{
        width: 100%;
        height: 100vh;
        background: url(../images/home/mv_pc.jpg) no-repeat center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mv_catch{
        img{
            max-width: 100%;
        }
    }
}//mv

#concept{
    @if $type == sp{
    }
    @if $type == pc{
    }
    p.jp{
        text-align: center;
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 40px;
        }
    }
    p.en{
        text-align: center;
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            font-size: 16px;
            margin-top: 35px;
        }
    }
}//concept

#movie{
    .common_inner{
        @if $type == sp{
        }
        @if $type == pc{
            max-width: 90%;
        }
    }
    .movie_list{
        @if $type == sp{
            margin-top: 45px;
        }
        @if $type == pc{
            display: flex;
            flex-wrap: wrap;
            margin-top: 90px;
        }
        li{
            overflow: hidden;
            color: #fff;
            position: relative;
            @if $type == sp{
                width: 100%;
                height: 170px;
                &:not(:first-of-type){
                    margin-top: 40px;
                }
            }
            @if $type == pc{
                width: calc(50% - 25px);
                height: 340px;
                font-size: 16px;
                &:hover{
                    cursor: pointer;
                }
                &:nth-of-type(odd){
                    margin-right: 50px;
                }
                &:nth-of-type(n + 3){
                    margin-top: 50px;
                }
            }
            &:nth-of-type(1):before{
                content: "MOVIE 01";
            }
            &:nth-of-type(2):before{
                content: "MOVIE 02";
            }
            &:nth-of-type(3):before{
                content: "MOVIE 03";
            }
            &:nth-of-type(4):before{
                content: "MOVIE 04";
            }
            &:before{
                color: #fff;
                font-family: $ff_en;
                letter-spacing: 0.05em;
                position: absolute;
                left: 50%;
                z-index: 2;
                transform: translateX(-50%);
                @if $type == sp{
                    top: 45px;
                    font-size: 12px;
                }
                @if $type == pc{
                    top: 110px;
                }
            }
            &:after{
                content: "";
                display: inline-block;
                background: url(../images/common/icon_play.png) no-repeat;
                background-size: cover;
                margin-top: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                @if $type == sp{
                    width: 40px;
                    height: 40px;
                }
                @if $type == pc{
                    width: 65px;
                    height: 65px;
                }
            }
            span{
                display: block;
                width: 100%;
                height: 100%;
                @if $type == pc{
                    transform: scale(1);
                    transition: transform 1.2s cubic-bezier(.24, .12, 0, 1);
                    &:hover{
                        transform: scale(1.08);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}//movie

#product{
    @if $type == sp{
    }
    @if $type == pc{
    }
    .product_list{
        @if $type == sp{
            margin-top: 60px;
        }
        @if $type == pc{
            margin-top: 100px;
        }
    }
}//product

#recipe{
    @if $type == sp{
    }
    @if $type == pc{
    }
    .recipe_list{
        @if $type == sp{
            margin-top: 50px;
        }
        @if $type == pc{
            margin-top: 70px;
            display: flex;
        }
        li{
            overflow: hidden;
            position: relative;
            @if $type == sp{
                width: 100%;
                height: 225px;
                + li{
                    margin-top: 5px;
                }
            }
            @if $type == pc{
                width: calc(100% / 3 - 2px);
                height: 330px;
                &:not(:nth-of-type(3n)){
                    margin-right: 3px;
                }
            }
            a{
                width: 100%;
                height: 100%;
                display: block;
                text-decoration: none;
                color: $c_text;
                @if $type == sp{
                }
                @if $type == pc{
                    transform: scale(1);
                    transition: transform 1.2s cubic-bezier(.24, .12, 0, 1);
                    &:hover{
                        transform: scale(1.08);
                        cursor: pointer;
                    }
                }
            }
            h3{
                color: #fff;
                display: inline-block;
                background-color: $c_base;
                line-height: 1.2;
                font-weight: 600;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                @if $type == sp{
                    font-size: 16px;
                    padding: 10px 15px;
                }
                @if $type == pc{
                    padding: 10px 20px;
                }
            }
        }
    }
    .btn{
        text-align: center;
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 40px;
        }
        .more_btn{
            @if $type == sp{
                width: 70%;
            }
            @if $type == pc{
                min-width: 255px;
                &:hover{
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
    }
}//recipe

#apeluca_style{
    @if $type == sp{
        padding: 0 25px 80px;
    }
    @if $type == pc{
        padding: 0 0 100px 0;
    }
    .common_inner{
        background-color: #fff;
        @if $type == sp{
            padding: 35px 15px;
        }
        @if $type == pc{
            padding: 60px;
        }
    }
    .style_list{
        @if $type == sp{
            margin-top: 25px;
        }
        @if $type == pc{
            display: flex;
            flex-wrap: wrap;
            margin-top: 60px;
        }
        .item{
            @if $type == sp{
                display: flex;
                flex-wrap: wrap;
                a{
                    width: calc(50% - 6px);
                    display: block;
                    margin: 3px;
                    img{
                        width: 100%;
                    }
                }
            }
            @if $type == pc{
                width: calc(50% - 2px);
                display: flex;
                flex-wrap: wrap;
                &:nth-of-type(odd){
                    margin-right: 4px;
                }
                a{
                    display: block;
                    width: calc(50% - 2px);
                    transition: opacity .3s;
                    &:nth-of-type(odd){
                        margin-right: 4px;
                    }
                    &:nth-of-type(n + 3){
                        margin-top: 4px;
                    }
                    img{
                        width: 100%;
                    }
                    &:hover{
                        opacity: .8;
                    }
                }
            }
        }
    }
    .btn{
        @if $type == sp{
            margin-top: 20px;
            text-align: center;
        }
        @if $type == pc{
            margin-top: 40px;
            text-align: center;
        }
        a{
            border-radius: 3px;
            line-height: 1;
            display: inline-block;
            text-decoration: none;
            border: 1px solid $c_text;
            transition: .3s;
            text-align: left;
            color: $c_text;
            @if $type == sp{
                font-size: 13px;
                padding: 15px 15px 10px 115px;
                width: 90%;
                background: url(../images/home/check.png) no-repeat left 15px center;
                background-size: 90px;
            }
            @if $type == pc{
                font-size: 16px;
                padding: 30px 40px 25px 210px;
                background: url(../images/home/check.png) no-repeat left 45px center;
                &:hover{
                    background: url(../images/home/check_w.png) no-repeat left 45px center;
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
    }
}

.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}
