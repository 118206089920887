@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=Shippori+Mincho+B1:wght@400;600;700&display=swap");
@media screen and (max-width: 1000px) {
  .more_btn {
    font-size: 16px;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #231815;
    transition: .3s;
    text-align: center;
    color: #231815;
    padding: 16px 40px 16px;
    width: 90%; }
  section {
    padding: 60px 0; }
    section.bg_gray {
      background-color: #f1f1f1; }
  h2 {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 28px; }
  .sub_h2 {
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    font-size: 11px; }
  #mv {
    width: 100%;
    height: 100vh;
    background: url(../images/home/mv_sp.jpg) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center; }
    #mv .mv_catch img {
      max-width: 100%; }
  #concept p.jp {
    text-align: center;
    margin-top: 30px; }
  #concept p.en {
    text-align: center;
    margin-top: 30px; }
  #movie .movie_list {
    margin-top: 45px; }
    #movie .movie_list li {
      overflow: hidden;
      color: #fff;
      position: relative;
      width: 100%;
      height: 170px; }
      #movie .movie_list li:not(:first-of-type) {
        margin-top: 40px; }
      #movie .movie_list li:nth-of-type(1):before {
        content: "MOVIE 01"; }
      #movie .movie_list li:nth-of-type(2):before {
        content: "MOVIE 02"; }
      #movie .movie_list li:nth-of-type(3):before {
        content: "MOVIE 03"; }
      #movie .movie_list li:nth-of-type(4):before {
        content: "MOVIE 04"; }
      #movie .movie_list li:before {
        color: #fff;
        font-family: "Crimson Text", serif;
        letter-spacing: 0.05em;
        position: absolute;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        top: 45px;
        font-size: 12px; }
      #movie .movie_list li:after {
        content: "";
        display: inline-block;
        background: url(../images/common/icon_play.png) no-repeat;
        background-size: cover;
        margin-top: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px; }
      #movie .movie_list li span {
        display: block;
        width: 100%;
        height: 100%; }
  #product .product_list {
    margin-top: 60px; }
  #recipe .recipe_list {
    margin-top: 50px; }
    #recipe .recipe_list li {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 225px; }
      #recipe .recipe_list li + li {
        margin-top: 5px; }
      #recipe .recipe_list li a {
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;
        color: #231815; }
      #recipe .recipe_list li h3 {
        color: #fff;
        display: inline-block;
        background-color: #231815;
        line-height: 1.2;
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        font-size: 16px;
        padding: 10px 15px; }
  #recipe .btn {
    text-align: center;
    margin-top: 30px; }
    #recipe .btn .more_btn {
      width: 70%; }
  #apeluca_style {
    padding: 0 25px 80px; }
    #apeluca_style .common_inner {
      background-color: #fff;
      padding: 35px 15px; }
    #apeluca_style .style_list {
      margin-top: 25px; }
      #apeluca_style .style_list .item {
        display: flex;
        flex-wrap: wrap; }
        #apeluca_style .style_list .item a {
          width: calc(50% - 6px);
          display: block;
          margin: 3px; }
          #apeluca_style .style_list .item a img {
            width: 100%; }
    #apeluca_style .btn {
      margin-top: 20px;
      text-align: center; }
      #apeluca_style .btn a {
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #231815;
        transition: .3s;
        text-align: left;
        color: #231815;
        font-size: 13px;
        padding: 15px 15px 10px 115px;
        width: 90%;
        background: url(../images/home/check.png) no-repeat left 15px center;
        background-size: 90px; }
  /*
Colorbox Core Style:
The following CSS is consistent between example themes and should not be altered.
*/
  #colorbox, #cboxOverlay, #cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  #cboxWrapper {
    max-width: none; }
  #cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%; }
  #cboxMiddleLeft, #cboxBottomLeft {
    clear: left; }
  #cboxContent {
    position: relative; }
  #cboxLoadedContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  #cboxTitle {
    margin: 0; }
  #cboxLoadingOverlay, #cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
    cursor: pointer; }
  .cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic; }
  .cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0; }
  #colorbox, #cboxContent, #cboxLoadedContent {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; }
  /*
User Style:
Change the following styles to modify the appearance of Colorbox.  They are
ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
  #cboxOverlay {
    background: #fff;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  #colorbox {
    outline: 0; }
  #cboxContent {
    margin-top: 32px;
    overflow: visible;
    background: #000; }
  .cboxIframe {
    background: #fff; }
  #cboxError {
    padding: 50px;
    border: 1px solid #ccc; }
  #cboxLoadedContent {
    background: #000;
    padding: 1px; }
  #cboxLoadingGraphic {
    background: url(../images/loading.gif) no-repeat center center; }
  #cboxLoadingOverlay {
    background: #000; }
  #cboxTitle {
    position: absolute;
    top: -22px;
    left: 0;
    color: #000; }
  #cboxCurrent {
    position: absolute;
    top: -22px;
    right: 205px;
    text-indent: -9999px; }
  /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
  #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    background: url(../images/controls.png) no-repeat 0 0; }
  /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
  #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
    outline: 0; }
  #cboxPrevious {
    background-position: 0px 0px;
    right: 44px; }
  #cboxPrevious:hover {
    background-position: 0px -25px; }
  #cboxNext {
    background-position: -25px 0px;
    right: 22px; }
  #cboxNext:hover {
    background-position: -25px -25px; }
  #cboxClose {
    background-position: -50px 0px;
    right: 0; }
  #cboxClose:hover {
    background-position: -50px -25px; }
  .cboxSlideshow_on #cboxPrevious, .cboxSlideshow_off #cboxPrevious {
    right: 66px; }
  .cboxSlideshow_on #cboxSlideshow {
    background-position: -75px -25px;
    right: 44px; }
  .cboxSlideshow_on #cboxSlideshow:hover {
    background-position: -100px -25px; }
  .cboxSlideshow_off #cboxSlideshow {
    background-position: -100px 0px;
    right: 44px; }
  .cboxSlideshow_off #cboxSlideshow:hover {
    background-position: -75px -25px; } }

@media screen and (min-width: 1000px) {
  .more_btn {
    font-size: 16px;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #231815;
    transition: .3s;
    text-align: center;
    color: #231815; }
  section.bg_gray {
    background-color: #f1f1f1; }
  h2 {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 0.05em; }
  .sub_h2 {
    font-weight: 600;
    text-align: center; }
  #mv .mv_catch img {
    max-width: 100%; }
  #concept p.jp {
    text-align: center; }
  #concept p.en {
    text-align: center; }
  #movie .movie_list li {
    overflow: hidden;
    color: #fff;
    position: relative; }
    #movie .movie_list li:nth-of-type(1):before {
      content: "MOVIE 01"; }
    #movie .movie_list li:nth-of-type(2):before {
      content: "MOVIE 02"; }
    #movie .movie_list li:nth-of-type(3):before {
      content: "MOVIE 03"; }
    #movie .movie_list li:nth-of-type(4):before {
      content: "MOVIE 04"; }
    #movie .movie_list li:before {
      color: #fff;
      font-family: "Crimson Text", serif;
      letter-spacing: 0.05em;
      position: absolute;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%); }
    #movie .movie_list li:after {
      content: "";
      display: inline-block;
      background: url(../images/common/icon_play.png) no-repeat;
      background-size: cover;
      margin-top: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    #movie .movie_list li span {
      display: block;
      width: 100%;
      height: 100%; }
  #recipe .recipe_list li {
    overflow: hidden;
    position: relative; }
    #recipe .recipe_list li a {
      width: 100%;
      height: 100%;
      display: block;
      text-decoration: none;
      color: #231815; }
    #recipe .recipe_list li h3 {
      color: #fff;
      display: inline-block;
      background-color: #231815;
      line-height: 1.2;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
  #recipe .btn {
    text-align: center; }
  #apeluca_style .common_inner {
    background-color: #fff; }
  #apeluca_style .btn a {
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #231815;
    transition: .3s;
    text-align: left;
    color: #231815; }
  /*
Colorbox Core Style:
The following CSS is consistent between example themes and should not be altered.
*/
  #colorbox, #cboxOverlay, #cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  #cboxWrapper {
    max-width: none; }
  #cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%; }
  #cboxMiddleLeft, #cboxBottomLeft {
    clear: left; }
  #cboxContent {
    position: relative; }
  #cboxLoadedContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  #cboxTitle {
    margin: 0; }
  #cboxLoadingOverlay, #cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
    cursor: pointer; }
  .cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic; }
  .cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0; }
  #colorbox, #cboxContent, #cboxLoadedContent {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; }
  /*
User Style:
Change the following styles to modify the appearance of Colorbox.  They are
ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
  #cboxOverlay {
    background: #fff;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  #colorbox {
    outline: 0; }
  #cboxContent {
    margin-top: 32px;
    overflow: visible;
    background: #000; }
  .cboxIframe {
    background: #fff; }
  #cboxError {
    padding: 50px;
    border: 1px solid #ccc; }
  #cboxLoadedContent {
    background: #000;
    padding: 1px; }
  #cboxLoadingGraphic {
    background: url(../images/loading.gif) no-repeat center center; }
  #cboxLoadingOverlay {
    background: #000; }
  #cboxTitle {
    position: absolute;
    top: -22px;
    left: 0;
    color: #000; }
  #cboxCurrent {
    position: absolute;
    top: -22px;
    right: 205px;
    text-indent: -9999px; }
  /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
  #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    background: url(../images/controls.png) no-repeat 0 0; }
  /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
  #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
    outline: 0; }
  #cboxPrevious {
    background-position: 0px 0px;
    right: 44px; }
  #cboxPrevious:hover {
    background-position: 0px -25px; }
  #cboxNext {
    background-position: -25px 0px;
    right: 22px; }
  #cboxNext:hover {
    background-position: -25px -25px; }
  #cboxClose {
    background-position: -50px 0px;
    right: 0; }
  #cboxClose:hover {
    background-position: -50px -25px; }
  .cboxSlideshow_on #cboxPrevious, .cboxSlideshow_off #cboxPrevious {
    right: 66px; }
  .cboxSlideshow_on #cboxSlideshow {
    background-position: -75px -25px;
    right: 44px; }
  .cboxSlideshow_on #cboxSlideshow:hover {
    background-position: -100px -25px; }
  .cboxSlideshow_off #cboxSlideshow {
    background-position: -100px 0px;
    right: 44px; }
  .cboxSlideshow_off #cboxSlideshow:hover {
    background-position: -75px -25px; } }

@media print, screen and (min-width: 1001px) {
  .more_btn {
    font-size: 16px;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #231815;
    transition: .3s;
    text-align: center;
    color: #231815;
    padding: 16px 40px 13px; }
  section {
    padding: 100px 0; }
    section.bg_gray {
      background-color: #f1f1f1; }
  h2 {
    text-align: center;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 38px; }
  .sub_h2 {
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    margin-top: 5px; }
  #mv {
    width: 100%;
    height: 100vh;
    background: url(../images/home/mv_pc.jpg) no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center; }
    #mv .mv_catch img {
      max-width: 100%; }
  #concept p.jp {
    text-align: center;
    margin-top: 40px; }
  #concept p.en {
    text-align: center;
    font-size: 16px;
    margin-top: 35px; }
  #movie .common_inner {
    max-width: 90%; }
  #movie .movie_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px; }
    #movie .movie_list li {
      overflow: hidden;
      color: #fff;
      position: relative;
      width: calc(50% - 25px);
      height: 340px;
      font-size: 16px; }
      #movie .movie_list li:hover {
        cursor: pointer; }
      #movie .movie_list li:nth-of-type(odd) {
        margin-right: 50px; }
      #movie .movie_list li:nth-of-type(n + 3) {
        margin-top: 50px; }
      #movie .movie_list li:nth-of-type(1):before {
        content: "MOVIE 01"; }
      #movie .movie_list li:nth-of-type(2):before {
        content: "MOVIE 02"; }
      #movie .movie_list li:nth-of-type(3):before {
        content: "MOVIE 03"; }
      #movie .movie_list li:nth-of-type(4):before {
        content: "MOVIE 04"; }
      #movie .movie_list li:before {
        color: #fff;
        font-family: "Crimson Text", serif;
        letter-spacing: 0.05em;
        position: absolute;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        top: 110px; }
      #movie .movie_list li:after {
        content: "";
        display: inline-block;
        background: url(../images/common/icon_play.png) no-repeat;
        background-size: cover;
        margin-top: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65px;
        height: 65px; }
      #movie .movie_list li span {
        display: block;
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: transform 1.2s cubic-bezier(0.24, 0.12, 0, 1); }
        #movie .movie_list li span:hover {
          transform: scale(1.08);
          cursor: pointer; }
  #product .product_list {
    margin-top: 100px; }
  #recipe .recipe_list {
    margin-top: 70px;
    display: flex; }
    #recipe .recipe_list li {
      overflow: hidden;
      position: relative;
      width: calc(100% / 3 - 2px);
      height: 330px; }
      #recipe .recipe_list li:not(:nth-of-type(3n)) {
        margin-right: 3px; }
      #recipe .recipe_list li a {
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;
        color: #231815;
        transform: scale(1);
        transition: transform 1.2s cubic-bezier(0.24, 0.12, 0, 1); }
        #recipe .recipe_list li a:hover {
          transform: scale(1.08);
          cursor: pointer; }
      #recipe .recipe_list li h3 {
        color: #fff;
        display: inline-block;
        background-color: #231815;
        line-height: 1.2;
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 10px 20px; }
  #recipe .btn {
    text-align: center;
    margin-top: 40px; }
    #recipe .btn .more_btn {
      min-width: 255px; }
      #recipe .btn .more_btn:hover {
        background-color: #231815;
        color: #fff; }
  #apeluca_style {
    padding: 0 0 100px 0; }
    #apeluca_style .common_inner {
      background-color: #fff;
      padding: 60px; }
    #apeluca_style .style_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px; }
      #apeluca_style .style_list .item {
        width: calc(50% - 2px);
        display: flex;
        flex-wrap: wrap; }
        #apeluca_style .style_list .item:nth-of-type(odd) {
          margin-right: 4px; }
        #apeluca_style .style_list .item a {
          display: block;
          width: calc(50% - 2px);
          transition: opacity .3s; }
          #apeluca_style .style_list .item a:nth-of-type(odd) {
            margin-right: 4px; }
          #apeluca_style .style_list .item a:nth-of-type(n + 3) {
            margin-top: 4px; }
          #apeluca_style .style_list .item a img {
            width: 100%; }
          #apeluca_style .style_list .item a:hover {
            opacity: .8; }
    #apeluca_style .btn {
      margin-top: 40px;
      text-align: center; }
      #apeluca_style .btn a {
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #231815;
        transition: .3s;
        text-align: left;
        color: #231815;
        font-size: 16px;
        padding: 30px 40px 25px 210px;
        background: url(../images/home/check.png) no-repeat left 45px center; }
        #apeluca_style .btn a:hover {
          background: url(../images/home/check_w.png) no-repeat left 45px center;
          background-color: #231815;
          color: #fff; }
  /*
Colorbox Core Style:
The following CSS is consistent between example themes and should not be altered.
*/
  #colorbox, #cboxOverlay, #cboxWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); }
  #cboxWrapper {
    max-width: none; }
  #cboxOverlay {
    position: fixed;
    width: 100%;
    height: 100%; }
  #cboxMiddleLeft, #cboxBottomLeft {
    clear: left; }
  #cboxContent {
    position: relative; }
  #cboxLoadedContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  #cboxTitle {
    margin: 0; }
  #cboxLoadingOverlay, #cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
    cursor: pointer; }
  .cboxPhoto {
    float: left;
    margin: auto;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic; }
  .cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    padding: 0;
    margin: 0; }
  #colorbox, #cboxContent, #cboxLoadedContent {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; }
  /*
User Style:
Change the following styles to modify the appearance of Colorbox.  They are
ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
  #cboxOverlay {
    background: #fff;
    opacity: 0.9;
    filter: alpha(opacity=90); }
  #colorbox {
    outline: 0; }
  #cboxContent {
    margin-top: 32px;
    overflow: visible;
    background: #000; }
  .cboxIframe {
    background: #fff; }
  #cboxError {
    padding: 50px;
    border: 1px solid #ccc; }
  #cboxLoadedContent {
    background: #000;
    padding: 1px; }
  #cboxLoadingGraphic {
    background: url(../images/loading.gif) no-repeat center center; }
  #cboxLoadingOverlay {
    background: #000; }
  #cboxTitle {
    position: absolute;
    top: -22px;
    left: 0;
    color: #000; }
  #cboxCurrent {
    position: absolute;
    top: -22px;
    right: 205px;
    text-indent: -9999px; }
  /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
  #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
    border: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    background: url(../images/controls.png) no-repeat 0 0; }
  /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
  #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
    outline: 0; }
  #cboxPrevious {
    background-position: 0px 0px;
    right: 44px; }
  #cboxPrevious:hover {
    background-position: 0px -25px; }
  #cboxNext {
    background-position: -25px 0px;
    right: 22px; }
  #cboxNext:hover {
    background-position: -25px -25px; }
  #cboxClose {
    background-position: -50px 0px;
    right: 0; }
  #cboxClose:hover {
    background-position: -50px -25px; }
  .cboxSlideshow_on #cboxPrevious, .cboxSlideshow_off #cboxPrevious {
    right: 66px; }
  .cboxSlideshow_on #cboxSlideshow {
    background-position: -75px -25px;
    right: 44px; }
  .cboxSlideshow_on #cboxSlideshow:hover {
    background-position: -100px -25px; }
  .cboxSlideshow_off #cboxSlideshow {
    background-position: -100px 0px;
    right: 44px; }
  .cboxSlideshow_off #cboxSlideshow:hover {
    background-position: -75px -25px; } }
